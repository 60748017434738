@import "../../../../styles/variables";

.component {
  width: 750px;

  .templateIntro {
    padding-bottom: 10px;
  }

  .templateSection {
    padding: 0 0 20px 0;

    .sectionHeading {
      padding: 0 0 5px 0;
      font-weight: 600;

      .sectionIcon {
        vertical-align: top;
        margin-right: 3px;
        margin-top: 3px;
      }
    }
  }

  .warningCallout {
    margin-bottom: 10px;
  }

  .templatesList {
    display: flex;
    flex-wrap: wrap; /* Allows elements to wrap to the next row */
    align-items: flex-start; /* Aligns the rows to the top */
    gap: 10px; /* Optional: Adds space between squares */
  }

  .template {
    //display: block;
    //position: relative;
    //margin-bottom: 10px;
    //margin-bottom: 10px; /* Adds space between rows */

    border-radius: @border-radius;
    border: 1px solid #efefef;
    cursor: pointer;
    background: @white;

    height: 90px;
    width: calc(25% - 10px); /* Each square takes up 25% minus gap adjustment */
    position: relative;

    &.selected {
      //display: block;
      position: relative;
      border-radius: @border-radius;
      border: 1px solid @border-color-1;
      background: @white;
      //margin-bottom: 10px;
    }
  }

  .content {
    padding: 7px;

    .title {
      font-weight: 600;
      padding-bottom: 10px;
      padding-right: 16px;
      height: 20px;

      font-size: 0.9em;
      line-height: 1.3em;
    }

    .description {
      font-size: 0.8em;
      line-height: 1.5em;
    }
  }

  .checkboxContainer {
    position: absolute;
    top: 10px;
    right: 0;
  }

  .callout {
    margin-bottom: 30px;
  }

  .emptyPlaceholder {
    height: 60px;
  }

  .createWorkbookButton {
    padding-top: 10px;
    margin: auto;
  }
}
