import React, { Component } from 'react';
import templateScreenshot from '../../../../assets/images/onboarding-screenshot-shopify-template-workbook.png';
import blankScreenshot from '../../../../assets/images/onboarding-screenshot-blank-workbook.png';
import * as styles from './emptyWorkbooksCatalog.module.less';

/**
 * The EmptyWorkbooksCatalog Component within the Home Page
 */
class EmptyWorkbooksCatalog extends Component {
  constructor(props) {
    super(props);

    /* Parent functions we passed to the component */
    this.parentFunctions = {
      openAddShopifyWorkbookDialog: props.openAddShopifyWorkbookDialog,
      createWorkbook: props.createWorkbook,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className={styles.component}>
        <div className={styles.createWorkbookCards}>

          <div
            onClick={this.parentFunctions.openAddShopifyWorkbookDialog}
            className={styles.createShopifyStarterWorkbook}
          >
            <div className={styles.cardHeader}>
              <span className={'icon-shopify'} style={{ marginRight: '20px' }} />
              Create Shopify template workbook
            </div>
            <div className={styles.cardBody}>
              Start with our template workbook for Shopify stores. Includes all the data that stores most commonly need. You'll be able to add and remove data from the workbook, but the template is a great way to start.

              <div className={styles.screenshot}>
                <img src={templateScreenshot}/>
              </div>
            </div>
          </div>

          <div
            onClick={async () => { this.parentFunctions.createWorkbook(null, null); }}
            className={styles.createBlankWorkbook}
          >
            <div className={styles.cardHeader}>
              <span className={'icon-workbook'} style={{ marginRight: '20px' }} />
              Add blank workbook
            </div>
            <div className={styles.cardBody}>
              Add a blank workbook, similar to what you get when you create a new workbook in Excel. You can use this workbook just like you would Excel. You can also add new Shopify-linked worksheets to it as you need.

              <div className={styles.screenshot}>
                <img src={blankScreenshot}/>
              </div>
            </div>
          </div>


        </div>
      </div>
    );
  }
}

export default EmptyWorkbooksCatalog;
