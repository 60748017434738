import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet

import Routes from './routes';
import GoogleTagManager from './components/googleTagManager';

import './styles/reactStyles.css';
import './styles/blueprintjs/blueprint.css';
import './styles/blueprintjs/blueprint-datetime2.css';
import './styles/image-editor.css';
import './assets/fonts/Inter/inter.css';
import './assets/fonts/icomoon/icomoon.css';
import './styles/app.less';

const App = () => (
  <>
    {/* Helmet to manage <head> elements */}
    <Helmet>
      <title>Mixtable</title>
      <meta property="og:title" content={'Mixtable'}/>
      <meta property="og:url" content={'https://www.mixtable.com/'}/>
    </Helmet>

    <div>
      <Routes/>
      <GoogleTagManager gtmId={'GTM-N83LJP7'}/>
    </div>
  </>
);

export default App;
